<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
                @change="onSelectProject"
              ></a-select>
            </a-form-item>
            <a-form-item label="班组">
              <a-select
                class="form-control lg test"
                v-decorator="['leaderId']"
                placeholder="请选择"
                allowClear
                showSearch
                :filterOption="filterByPY"
                :options="leaderList"
              ></a-select>
            </a-form-item>
            <a-form-item label="身份证号">
              <a-input
                class="form-control"
                v-decorator="['userCardNo']"
                placeholder="请输入身份证号"
                allowClear
              ></a-input>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions"></div>

        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList, getLeaderList } from '@/service/getData'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '班组',
        width: '6%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '名称',
        width: '6%',
        align: 'center',
        dataIndex: 'userName',
      },
      {
        title: '身份证号码',
        width: 200,
        align: 'center',
        dataIndex: 'userCardNo',
      },
      {
        title: '核销详情',
        width: 180,
        align: 'center',
        customRender: (text, record) => {
          return (
            <div>
              {record.yearMonth && record.days && (
                <div>
                  {record.yearMonth} {record.days}天
                </div>
              )}
              {record.startDate && record.endDate && (
                <div>
                  {record.startDate} ~ {record.endDate}
                </div>
              )}
              {record.attendanceDate && <div>{record.attendanceDate}</div>}
            </div>
          )
        },
      },
      {
        title: '流程id',
        width: '6%',
        align: 'center',
        dataIndex: 'requestId',
      },
      {
        title: '来源说明',
        align: 'center',
        dataIndex: 'source',
      },

      {
        title: '锁定类型',
        width: '6%',
        align: 'center',
        dataIndex: 'type',
        customRender: (text, row, index) => {
          return <span>{this.translateType(text, 'type') || '-'}</span>
        },
      },
      {
        title: '状态',
        width: '6%',
        align: 'center',
        dataIndex: 'status',
        customRender: (text, row, index) => {
          return (
            <span class={{ 'text-yellow-400': text === 0, 'text-gray-400': text === 1 }}>
              {this.translateType(text, 'status') || '-'}
            </span>
          )
        },
      },
      {
        title: '操作',
        width: '6%',
        align: 'center',
        customRender: (text, row) => {
          return (
            <div>
              {!row.attendanceDate && row.status === 0 ? (
                <a
                  onClick={() => {
                    this.undoRow(row)
                  }}
                >
                  撤回
                </a>
              ) : (
                <span>-</span>
              )}
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      projectList: [],
      leaderList: [],
    }
  },
  computed: {
    typesMap() {
      return {
        type: [
          { value: 2, label: '抢工' },
          { value: 3, label: '正常工资单' },
          { value: 4, label: '异常工资单' },
          { value: 5, label: '签证' },
        ],
        status: [
          { value: 0, label: '已核销' },
          { value: 1, label: '已撤回' },
        ],
      }
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/attendance/web/lock/list', {
          ...this.formatTimeRangeParams(this.getPageParams(_pageNo, _pageSize), 'time', {
            format: 'YYYY-MM-DD',
          }),
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list.map((item) => {
          return {
            ...item,
            children: item.attendanceLockInfos.map((child) => {
              return {
                id: child.id,
                attendanceDate: child.attendanceDate,
              }
            }),
          }
        })
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async onSelectProject(projectCode) {
      this.form.setFieldsValue({ leaderId: undefined })
      this.leaderList = projectCode ? await getLeaderList(projectCode) : []
    },
    async undoRow(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确认撤回?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/attendance/web/lock/unlock', {
        lockNo: row.lockNo,
      })
      this.$message.success('操作成功')
      this.doQuery()
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
  },
}
</script>

<style lang="less" scoped></style>
